<!--
 * @Author: 候怀烨
 * @Date: 2021-01-19 14:59:00
 * @LastEditTime: 2021-01-19 14:59:46
 * @LastEditors: Please set LastEditors
 * @Description: 网销管理
 * @FilePath: \sd-vue-admin\src\views\project\marketing\management\index.vue
-->
<template>
  <router-view></router-view>
</template>

<script>
  export default {}
</script>

<style></style>
